import React from "react"
import { Link } from "gatsby"
import navbarStyles from "./navbar.module.css"

export default () => {
  return (
    <div className={navbarStyles.container}>
      <Link className={navbarStyles.navLogoText} to="/">
        <div className={navbarStyles.navLogoTextLarge}>Reino Muhl: <strong>Developer</strong></div>
      </Link>
      <Link className={navbarStyles.navLink} to="/services/">Services</Link>
      <Link className={navbarStyles.navLink} to="/contact/">Contact</Link>
      <Link className={navbarStyles.navLink} to="/about/">About</Link>
      <Link className={navbarStyles.navLink} to="/blog/">Blog</Link>
      {/* <Link className={navbarStyles.navLink} to="/uses/">Uses</Link> */}
      {/* <Link className={navbarStyles.navLink} to="/projects/">Projects</Link> */}
    </div>
  )
}
