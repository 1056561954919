import React from "react"
import { graphql, useStaticQuery } from "gatsby"
import avatar from "../images/avatar.jpg"
import bioStyles from "./bio.module.css"

export default () => {
  const data = useStaticQuery(
    graphql`
      query {
        site {
          siteMetadata {
            author
          }
        }
      }
    `
  );
    
  return (
    <div className={bioStyles.container}>
      <img className={bioStyles.avatar} src={avatar} alt="Reino Muhl :DEV:"/>
      <div className={bioStyles.author}>{data.site.siteMetadata.author}</div>
      <div className={bioStyles.jobTitle}>Freelance Software Developer</div>
    </div>
  )
}
