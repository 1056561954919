import React from "react"
import layoutStyles from "./layout.module.css"
import Bio from "./bio"
import Navbar from "./navbar"
import Footer from "./footer"

export default ({ children }) => {

  return (
    <div className={layoutStyles.container}>
      <div className={layoutStyles.navbar2}>
        <nav className={layoutStyles.navbar}>
          <Navbar />
        </nav>
      </div>
      
      <header className={layoutStyles.header}>
        <Bio />
      </header>
      <main className={layoutStyles.main}>
        {children}
      </main>
      <footer className={layoutStyles.footer}>
        <Footer />
      </footer>
    </div>
  )
}
