import React from "react"
import footerStyles from "./footer.module.css"

import twitterLogo from "../images/Twitter_Logo_Blue.svg"
import linkedinLogo from "../images/LI-In-Bug.png"
import githubLogo from "../images/Octicons-mark-github.svg"
import instagramLogo from "../images/Instagram_simple_icon.svg"
import emailLogo from "../images/Email_OneDrive_icon.svg"

export default () => {
  return (
    <div className={footerStyles.container}>
      <div className={footerStyles.copyright}>Copyright 2020 RSM Dev</div>
      <a href="mailto:reino.muhl@gmail.com">
        <img src={emailLogo} alt="Email" className={footerStyles.icons} />
      </a>
      <a href="https://www.linkedin.com/in/reino-muhl-6826543a/" >
        <img src={linkedinLogo} alt="LinkedIn" className={footerStyles.icons} id={footerStyles.linkedinicon} />
      </a>
      <a href="https://github.com/StaberindeZA" >
        <img src={githubLogo} alt="Github" className={footerStyles.icons} />
      </a>
      <a href="https://twitter.com/quixoticZA" >
        <img src={twitterLogo} alt="Twitter" className={footerStyles.icons} />
      </a>
      <a href="https://www.instagram.com/staberindeza" >
        <img src={instagramLogo} alt="Instagram" className={footerStyles.icons} />
      </a>
    </div>
  )
}